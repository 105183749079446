<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="card-header pb-0 text-left">
                  <h4 class="font-weight-bolder">Sign Up</h4>
                  <p class="mb-0">Enter your information to register</p>
                </div>
                <div class="card-body pb-3">
                  <form role="form" @submit.prevent="submit">
                    <soft-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      :value="email"
                      @input="email = $event.target.value"
                    />
                    <div v-show="validateEmail()">
                    <soft-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      :value="password"
                      @input="password = $event.target.value"
                    />
                      <small v-show="!validatePassword() && this.password.length !== 0">Use 8 or more characters with a mix of letters, numbers & symbols</small>
                    </div>
                    <div v-show="validatePassword()">
                      <soft-input
                        id="password2"
                        type="password"
                        placeholder="Enter the password again"
                        aria-label="Password"
                        :value="password2"
                        @input="password2 = $event.target.value"
                      />
                      <small v-show="!validatePassword() && this.password.length !== 0">Use 8 or more characters with a mix of letters, numbers & symbols</small>
                    </div>
                    <div v-show="this.password === this.password2 && validatePassword()">
                    <soft-checkbox
                      id="flexCheckDefault"
                      class="font-weight-light"
                      name="terms"
                      :checked="checked"
                      @change="checked = !checked"
                    >
                      I agree the
                      <a href="#" class="text-dark font-weight-bolder"
                        >Terms and Conditions</a
                      >
                    </soft-checkbox>
                    </div>
                    <div class="text-center">
                      <div v-show="checked" id="recaptcha-container"></div>
                      <soft-button
                        id="submit"
                        color="success"
                        variant="gradient"
                        full-width
                        class="w-100 mt-4 mb-0"
                        :disabled="disabled || !checked || !validateUsername() || !validateEmail() || !validatePassword()"
                        >Sign up</soft-button
                      >
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Already have an account?
                    <router-link
                      :to="{ name: 'Signin' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign in
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-success h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Your journey starts here
                </h4>
                <p class="text-white">
                  Effortlessly create and translate subtitles with our powerful software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftInput from "@/components/Soft/SoftInput.vue";
import SoftCheckbox from "@/components/Soft/SoftCheckbox.vue";
import SoftButton from "@/components/Soft/SoftButton.vue";
import axios from 'axios';
const body = document.getElementsByTagName("body")[0];
import {nanoid} from "nanoid";

import { mapMutations } from "vuex";
import { onMounted } from "vue";
export default {
  name: "Signup",
  components: {
    SoftInput,
    SoftCheckbox,
    SoftButton,
  },
  setup() {
    onMounted(() => {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    });
  },
  data() {
    return {
      password2: "",
      password: "",
      disabled: false,
      cdata: "",
      token: "",
      email: "",
      checked: false,
    };
  },
  created() {
    this.cdata = nanoid(10);
    window.onloadTurnstileCallback = () => {
      window.turnstile.render("#recaptcha-container", {
        sitekey: "0x4AAAAAAABffqkUtZpTv4bi",
        action: 'register',
        cData: this.cdata,
        callback: (response) => {
          this.token = response;
        },
      });
    };
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    submit() {
      this.disabled = true;
      axios.post("https://api.365.day/v1/auth", {
          // username: this.username,
          password: this.password,
          email: this.email,
          recaptcha_response: this.token,
          cdata: this.cdata,
        })
        .then(() => {
          this.showSwal('success', 'Success', 'You have successfully registered');
        })
        .catch((error) => {
          this.showSwal('warning', 'Warning', error.response.data);
          this.disabled = false;
        });
    },
    showSwal(type, title, text) {
      if (type === "warning") {
        this.$swal({
          title: title,
          text: text,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      } else if (type === "success") {
        this.$swal({
          title: title,
          text: text,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          preConfirm: () => {
            this.$router.push({ name: "Signin" });
          },
          buttonsStyling: false,
        });
      }
    },
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
    validateUsername() {
      if (/^[a-zA-Z0-9]{3,12}$/.test(this.username)) {
        return true;
      } else {
        return false;
      }
    },
    validatePassword() {
      if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,100}$/.test(this.password)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
