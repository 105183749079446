<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Details</h5>
    <div class="mt-3 multisteps-form__content">
      <div class="row">
        <div class="col">
          <label>Task Name</label>
          <input
            class="multisteps-form__input form-control"
            type="text"
            placeholder="For identifying different tasks"
            :value="this.$parent.projectName"
            @input="this.$parent.projectName = $event.target.value"
          />
        </div>
      </div>

      <!-- Use Purpose -->
      <div class="col-12">
        <label>Use Purpose</label>
        <select
          id="use-purpose"
          class="form-control"
          name="use-purpose"
          @change="onPurposeChange($event.target.value)"
        >
          <option v-if="isVideo" value="video-to-speech">Video to speech</option>
          <option v-if="isVideo || isAudio" value="generate-subtitles" selected="">Generate subtitles</option>
          <option v-if="isSubtitle" value="translate-subtitles" selected="">Translate subtitles</option>
        </select>
      </div>


      <!-- Voice Recognition Provider -->
      <div class="col-12" v-show="['generate-subtitles'].includes(usePurpose)&&(isAudio||isVideo)">
        <label>Voice Recognition Provider</label>
        <select
          id="choices"
          class="form-control"
          name="choices-sizes"
          @change="updateProvider($event)"
        >
          <option value="Openai">Openai</option>
          <option value="IBM">IBM</option>
          <option value="Azure">Azure</option>
          <option value="Amazon">Amazon</option>
          <option value="Aliyun">Aliyun</option>
          <option value="Tencent Cloud">Tencent Cloud</option>
          <option value="iFlvtek">iFlvtek</option>
          <option value="Google">Google</option>
        </select>
      </div>

      <div v-if="provider === 'IBM'&&(isVideo||isAudio)">
        <label class="mt-4 form-label">Language of this {{fileType}}</label>
        <select
          v-model="selectedLanguageModel"
          class="form-control"
        >
          <option v-for="model in languageModels_IBM" :key="model.value" :value="model.value">
            {{ model.label }}
          </option>
        </select>
      </div>

      <div v-if="provider === 'Openai'&&(isVideo||isAudio)">
        <label class="mt-4 form-label">Language of this {{fileType}}</label>
        <select
          v-model="selectedLanguageModel"
          class="form-control"
        >
          <option v-for="model in languageModels_Openai" :key="model.value" :value="model.value">
            {{ model.label }}
          </option>
        </select>
      </div>


      <!-- Subtitles Translation -->
      <div class="col-12" v-show="['generate-subtitles', 'translate-subtitles'].includes(usePurpose)">
        <soft-checkbox
          id="flexCheckDefault"
          class="font-weight-light"
          name="terms"
          :checked="checked"
          @change="checked = !checked"
          v-show="!isSubtitle"
        >
          Translate to other languages when subtitle is generated
        </soft-checkbox>

        <div class="row" v-show="checked||isSubtitle">
          <div class="col-12">
            <label class="mt-4 form-label">Target Language</label>
            <select
              id="choices-tags"
              ref="choicesTags"
              class="form-control"
              name="choices-tags"
              multiple
            >
            </select>
          </div>

          <!-- Translation Provider -->
          <div class="col-12">
            <label>Translation Provider</label>
            <select
              id="translation-provider"
              class="form-control"
              name="translation-provider"
            >
              <option value="google">Google</option>
              <option value="Amazon">Amazon</option>
              <option value="Microsoft Azure">Microsoft Azure</option>
              <option value="IBM">IBM</option>
              <option value="Yandex">Yandex</option>
              <option value="Caiyun">Caiyun</option>
              <option value="Tencent">Tencent</option>
              <option value="Baidu">Baidu</option>
            </select>
          </div>
        </div>
      </div>



      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="secondary"
          variant="gradient"
          class="mb-0 js-btn-prev"
          title="Prev"
          @click="this.$parent.prevStep"
        >Prev</soft-button
        >
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="submit()"
          :disabled="this.$parent.projectName === ''"
        >Submit</soft-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Choices from "choices.js";
import SoftButton from "@/components/Soft/SoftButton.vue";
import SoftCheckbox from "@/components/Soft/SoftCheckbox.vue";

export default {
  name: "Details",
  components: {
    SoftButton,
    SoftCheckbox,
  },
  computed: {
    isVideo() {
      return this.fileType === "video";
    },
    isAudio() {
      return this.fileType === "audio";
    },
    isDocument() {
      return this.fileType === "document";
    },
    isSubtitle() {
      return this.fileType === "subtitle";
    },
  },
  data() {
    return {
      subtitle: "",
      values2: [],
      project_name: "",
      provider: "Openai",
      fileType: "",
      target_language: [],
      checked: false,
      usePurpose: "generate-subtitles",
      selectedLanguageModel: '',
      languageModels_IBM: [
        { value: 'ar-MS_BroadbandModel', label: 'Arabic' },
        { value: 'cs-CZ_BroadbandModel', label: 'Czech' },
        { value: 'de-DE_BroadbandModel', label: 'German' },
        { value: 'en-AU_BroadbandModel', label: 'English - AU' },
        { value: 'en-GB_BroadbandModel', label: 'English - GB' },
        { value: 'en-US_BroadbandModel', label: 'English - US' },
        { value: 'es-AR_BroadbandModel', label: 'Spanish - AR' },
        { value: 'es-CL_BroadbandModel', label: 'Spanish - CL' },
        { value: 'es-CO_BroadbandModel', label: 'Spanish - CO' },
        { value: 'es-ES_BroadbandModel', label: 'Spanish - ES' },
        { value: 'es-MX_BroadbandModel', label: 'Spanish - MX' },
        { value: 'es-PE_BroadbandModel', label: 'Spanish - PE' },
        { value: 'fr-CA_BroadbandModel', label: 'French - CA' },
        { value: 'fr-FR_BroadbandModel', label: 'French - FR' },
        { value: 'hi-IN_BroadbandModel', label: 'Hindi' },
        { value: 'it-IT_BroadbandModel', label: 'Italian' },
        { value: 'ja-JP_BroadbandModel', label: 'Japanese' },
        { value: 'ko-KR_BroadbandModel', label: 'Korean' },
        { value: 'nl-NL_BroadbandModel', label: 'Dutch' },
        { value: 'pt-BR_BroadbandModel', label: 'Portuguese - BR' },
        { value: 'sv-SE_BroadbandModel', label: 'Swedish' },
        { value: 'zh-CN_BroadbandModel', label: 'Chinese' },
      ],
      languageModels_Openai: [
        {value: "zh", label: "Chinese"},
        {value: "en", label: "English"},
{value: "fr", label: "French"},
{value: "de", label: "German"},
{value: "it", label: "Italian"},
{value: "ja", label: "Japanese"},
{value: "ko", label: "Korean"},
{value: "pt", label: "Portuguese"},
{value: "ru", label: "Russian"},
{value: "es", label: "Spanish"},
      ]
    };
  },
  watch: {
    '$parent.selectedFile': function (newVal) {
      if (newVal) {
        this.fileType = this.getFileType(newVal.name);
      }
    },
  },
  mounted() {
    if (document.getElementById("choices-tags")) {
      const examples = new Choices("#choices-tags", {
        removeItemButton: true,
        maxItemCount: 5,
      });

      examples.setChoices(
        [
          {
            value: "zh",
            label: "Chinese"
          },
          {
            value: "en",
            label: "English",
            selected: true,
          },
          {
            value: "fr",
            label: "French"
          },
          {
            value: "de",
            label: "German"
          },
          {
            value: "it",
            label: "Italian"
          },
          {
            value: "ja",
            label: "Japanese"
          },
          {
            value: "ko",
            label: "Korean"
          },
          {
            value: "pt",
            label: "Portuguese"
          },
          {
            value: "ru",
            label: "Russian"
          },
          {
            value: "es",
            label: "Spanish"
          },

        ],
        "value",
        "label",
        false
      );

      this.values2 = examples.getValue(true);
      this.$refs.choicesTags.addEventListener("change", this.cal);
    }
  },

  methods: {
    updateProvider(event) {
      this.provider = event.target.value;
    },
    cal() {
      var tags = this.$refs.choicesTags;
      var selectedValues = [];
      for (var i = 0; i < tags.options.length; i++) {
        if (tags.options[i].selected) {
          selectedValues.push(tags.options[i].value);
        }
      }
      this.values2 = selectedValues;
    },
    onPurposeChange(value) {
      this.usePurpose = value;
    },
    showSwal(type, title, text) {
      if (type === "warning") {
        this.$swal({
          title: title,
          text: text,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "success") {
        this.$swal({
          title: title,
          text: text,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          preConfirm: () => {
            this.$router.push({ name: "Tasks" });
          },
          buttonsStyling: false,
        });
      }
    },

    sendData() {
      const formData = new FormData();
      const data = {
        project_name: this.$parent.projectName,
        type: this.fileType,
        filename: this.$parent.selectedFile.name,
        purpose: this.usePurpose,
        ASR_provider: this.provider,
        ASR_model: this.selectedLanguageModel,
        translation: this.checked,
        trans_language: this.values2,
        trans_provider: document.getElementById("translation-provider").value,
      };

      Object.keys(data).forEach(key => {
        if (data[key] === "") {
          delete data[key];
        }
      });

      // 将JSON数据添加到FormData对象中
      formData.append("data", JSON.stringify(data));

      // 添加视频文件到FormData对象中
      formData.append("file", this.$parent.selectedFile);

      // 使用fetch API发送POST请求
      fetch("https://zws.365.day/api/v1/video-audio/file", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsImZ1YyI6IkYifQ.eyJpZCI6IjY0NDRkODgwMDU3YTA2NTU3MzUzMmEyMyIsIm9iaiI6e30sImV4cCI6MTY4NDgyNTU1OX0.W2oyNehAQPdNvp51krvN-caAGgOdQUkhL5FpYO0-BKekaJ-yiS7NuBKQwUS_BiaLs9uV6VGm2QojC2e0Ry4pNQQMWzp5Q2ZOAgPU3mVfjwK_z6FPnaDwmMfiQpuh68lnFhLyr0YjrwLAyrJor3_B5-U3lpGOkfi0-V2H3AM3ENBWtK7FVozU4J_ucyK82MCaDqS1v20YcQ9LxdtIxq5hCblEWWPLpXlWl0iDpqsZcar70QyLZPfUjeuyFwQnHyEOL2tN23Eahiogf_TsOOVWRLdHhfKu_I5PdnhfXC5CobZGVr8zmkBmXQaFEldwW2pSNBd9PqqWR0BkybcpKZEP2A",
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(() => {
          this.showSwal("success", "Success!", "Your project has been created.");

        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },

    getFileType(filename) {
      const ext = filename.split(".").pop().toLowerCase();
      if (["mp4", "avi", "mkv", "mov", "flv"].includes(ext)) {
        return "video";
      } else if (["mp3", "wav", "ogg", "flac"].includes(ext)) {
        return "audio";
      } else if (["pdf", "txt"].includes(ext)) {
        return "document";
      } else if (["vtt", "srt"].includes(ext)) {
        return "subtitle";
      }
      return "";
    },


    submit() {
      this.sendData();
          },
  },
};
</script>