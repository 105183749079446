<template>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <soft-input
        id="currentPassword"
        type="password"
        placeholder="Current Password"
      />
      <label class="form-label">New password</label>
      <soft-input id="newPassword" type="password" placeholder="New Password" />
      <label class="form-label">Confirm new password</label>
      <soft-input
        id="confirmPassword"
        type="password"
        placeholder="Confirm password"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 8 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">One upper and lower case letter</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <soft-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        >Update password</soft-button
      >
    </div>
  </div>
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Delete Account</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <soft-switch id="deleteAccount" name="deleteAccount" :checked="deleteAccountCheck"
                       @change="deleteAccountCheck = !deleteAccountCheck"/>
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Confirm</span
          >
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <soft-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        :disabled="!deleteAccountCheck"
        @click="showSwal"
        >Delete Account</soft-button
      >
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/Soft/SoftButton.vue";
import SoftSwitch from "@/components/Soft/SoftSwitch.vue";
import SoftInput from "@/components/Soft/SoftInput.vue";

export default {
  name: "SideNavItem",
  components: {
    SoftButton,
    SoftSwitch,
    SoftInput,
  },
  data() {
    return {
      deleteAccountCheck: false,
    };
  },
  methods: {
    showSwal() {
      this.$swal({
        title: "Success!",
        text: "Your account has been deleted.",
        icon: "success",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        preConfirm: () => {
          this.$router.push({ name: "Signin" });
        },
        buttonsStyling: false,
      });
    }
  },
};
</script>
