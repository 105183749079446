<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <List />
      </div>
    </div>
  </div>
</template>

<script>
import List from "./components/List.vue";

export default {
  name: "Media",
  components: {
    List,
  },
  data() {
    return {
      title: "Tasks",
      files : [
        {
          "project_name": "Youtube test video",
          "original_name": "Me at the zoo.mp4",
          "created_at": "2023-05-19 12:00:00",
          "purpose": "Generate subtitles",
          "id": "80431b53ac1b4183835957941485cb8a"
        }
      ]
    };
  },
};
</script>
