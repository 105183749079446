<template>
  <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">{{this.$parent.title}}</h5>
          <div class="button-row d-flex">
            <soft-button
              color="danger"
              class="ms-auto"
            >
              Delete
            </soft-button>
          </div>
        </div>
        <div class="table-responsive">
          <table id="datatable-search" class="table table-flush">
            <thead class="thead-light">
            <tr>
              <th>File Name</th>
              <th>Preview</th>
              <th>Download</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="file in this.$parent.files" :key="file.id">
              <td>
                <p class="text-sm text-secondary mb-0">{{ file.original_name }}</p>
              </td>
<!--              <td>-->
<!--              <span class="badge badge-dot me-4">-->
<!--                <i class="bg-info"></i>-->
<!--                <span class="text-dark text-xs">{{ file.status }}</span>-->
<!--              </span>-->
<!--              </td>-->
<!--              <td class="align-middle text-center text-sm">-->
<!--                <p class="text-secondary mb-0 text-sm">{{ file.project }}</p>-->
<!--              </td>-->
<!--              <td class="align-middle text-center">-->
<!--                <span class="text-secondary text-sm">{{ file.date }}</span>-->
<!--              </td>-->
<!--              <td class="align-middle text-center">-->
<!--                <span class="text-secondary text-sm">{{ file.size }}</span>-->
<!--              </td>-->
              <td class="align-middle text-center">
                <a
                  class="mb-0 btn bg-gradient-success"
                  :href="file.id ? 'https://tools.365.day/applications/play/' + file.id : ''"
                >
                  Play
                </a>
              </td>
              <td class="align-middle text-center">
                <a
                  :href="file.id ? 'https://r2.kpfd.com/' + file.original_name : ''"
                >
                <soft-button

                >
                  Download
                </soft-button>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import SoftButton from "@/components/Soft/SoftButton.vue";

export default {
  name: "Table",
  components: { SoftButton },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: true,
    });
  },
  methods: {
    showSwal() {
        this.$swal({
          title: "title",
          text: "text",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
};
</script>
