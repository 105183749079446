<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="mx-auto mt-4 mb-3 col-12 col-lg-8 mb-sm-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Preview"
                  :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0"
                >
                  1. Preview
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Pricing"
                  :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1"
                  :disabled="subtitle === ''"
                >
                  2. Submit
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <form class="mb-8 multisteps-form__form">
                <media
                  v-model:selected-file="selectedFile"
                  v-model:selected-file-url="selectedFileUrl"
                  v-model:type="type"
                  :class="activeStep === 0 ? activeClass : ''"
                />
                <submit-component
                  :class="activeStep === 1 ? activeClass : ''"
                />
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Media from "./components/Preview.vue";
// import Socials from "./components/Socials.vue";
import SubmitComponent from "./components/Submit.vue";

import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "MediaTranscribe",
  components: {
    Media,
    // Socials,
    SubmitComponent,
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      subtitle: "",
      projectName: "",
      provider: "",
      targetLanguage: [],
      selectedFile: null,
      selectedFileUrl: null,
      type: null,
    };
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    }
  }
};
</script>
