import { createRouter, createWebHistory } from "vue-router";
import Illustration from "../views/auth/Signin.vue";
import VerificationIllustration from "../views/auth/2FA.vue";
import SignupIllustration from "../views/auth/Signup.vue";
import MediaTranscribe from "../views/applications/MediaTranscribe.vue";
import AccountSetting from "@/views/settings/Account.vue";
import Tasks from "@/views/applications/Tasks.vue";
import File from "@/views/applications/File.vue";
import Play from "@/views/applications/Play.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/applications/media",
  },
  {
    path: "/applications/media",
    name: "Media Transcribe",
    component: MediaTranscribe,
  },
  {
    path: "/auth/signin",
    name: "Signin",
    component: Illustration,
  },
  {
    path: "/auth/2fa",
    name: "Verification",
    component: VerificationIllustration,
  },
  {
    path: "/auth/signup",
    name: "Signup",
    component: SignupIllustration,
  },
  {
    path: "/settings/account",
    name: "Account Setting",
    component: AccountSetting,
  },
  {
    path: "/applications/files",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/applications/files/:id",
    name: "File",
    component: File,
  },
  {
    path: "/applications/play/:id",
    name: "Play",
    component: Play,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
