<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Applications"
          :class="getRoute() === 'applications' ? 'active' : ''"
        >
          <template #icon>
            <Box3d />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Media Transcribe' }"
                mini-icon="M"
                text="Media Transcribe"
              />
              <sidenav-item
                :to="{ name: 'Tasks' }"
                mini-icon="T"
                text="Tasks"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="applicationsExamples"
          nav-text="Settings"
          :class="getRoute() === 'settings' ? 'active' : ''"
        >
          <template #icon>
            <Settings />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Account Setting' }"
                mini-icon="A"
                text="Account"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Settings from "../Icon/Settings.vue";
import Box3d from "../Icon/Box3d.vue";
// import Document from "../Icon/Document.vue";
// import Spaceship from "../Icon/Spaceship.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCollapseItem,
    Settings,
    // Basket,
    Box3d,
    // Shop,
    // Office,
    // Document,
    // Spaceship,
    // CreditCard,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
    computed: {
      ...mapState(["isRTL"]),
    },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
