<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Media</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12">
          <label>Please Select the Audio, Video or Subtitle File</label>
          <br/>
          <input type="file" accept="audio/*, video/*, .srt, .vtt"  @change="onChange">
          <br/>
          <div v-if="selectedFile">
            <audio v-if="type === 'audio'" :src="selectedFileUrl" controls></audio>
            <video v-else-if="type === 'video'" :src="selectedFileUrl" controls></video>
              <pre>{{ textFileContent }}</pre>
          </div>
          <div v-else-if="type === 'srt' || type === 'vtt'">
              <pre>{{ subtitlePreview }}</pre>
          </div>
          </div>


      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          :disabled="selectedFile === null"
          @click="this.$parent.nextStep"
        >Next</soft-button
        >
      </div>
    </div>
  </div>
  </div>

</template>

<script>
import SoftButton from "@/components/Soft/SoftButton.vue";

export default {
  name: "Preview",
  components: {
    SoftButton,
  },
  props: {
    selectedFile: {
      type: Object,
      default: () => null,
    },
    selectedFileUrl: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      textFileContent: null,
      subtitlePreview: null
    };
  },
  methods: {
    showSwal(type, title, text) {
      if (type === "warning") {
        this.$swal({
          title: title,
          text: text,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "success") {
        this.$swal({
          title: title,
          text: text,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          preConfirm: () => {
            this.$router.push({ name: "Verification Illustration" });
          },
          buttonsStyling: false,
        });
      }
    },
    async onChange(event) {
      const file = event.target.files[0];
      const fileType = file.type.split('/')[0];
      const fileExt = file.name.split('.').pop().toLowerCase();

      if (fileType === 'audio' || fileType === 'video' || fileExt === 'srt' || fileExt === 'vtt') {
        this.$emit('update:selectedFile', file);
        this.$emit('update:selectedFileUrl', URL.createObjectURL(file));
        this.$emit('update:type', fileType === 'audio' || fileType === 'video' ? fileType : fileExt);

        if (this.type === 'srt' || this.type === 'vtt') {
          this.subtitlePreview = await this.readSubtitleFile(file);
        }
      } else {
        this.$emit('update:selectedFile', null);
        this.$emit('update:selectedFileUrl', null);
        this.$emit('update:type', null);
        this.textFileContent = null;
        this.subtitlePreview = null;
      }
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
          resolve(event.target.result);
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.readAsText(file);
      });
    },
    async readSubtitleFile(file) {
      const content = await this.readFileAsText(file);
      const lines = content.split('\n');
      const previewLines = lines.slice(0, 10);
      return previewLines.join('\n');
    }
  }
};
</script>