<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-5 col-lg-6 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="text-center card-body px-lg-5 py-lg-5">
                  <div class="mb-4 text-center text-muted">
                    <h2>2-Step Verification</h2>
                  </div>
                  <div class="row gx-2 gx-sm-3">

                    <div v-show="!enabled" class="text-center">
                      <img
                        :src="otp_secret"
                        class="w-50" />
                      <label class="form-label">Scan the QR code with your authenticator app</label>
                    </div>

                    <div v-for="(item,index) in code" :key="index" class="col">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control form-control-lg text-center"
                                maxlength="1"
                                autocomplete="off"
                                autocapitalize="off"
                                @keyup="nextFocus($event,index)"
                                :value="code[index]"
                                @input="code[index] = $event.target.value"
                              />
                            </div>
                    </div>

                  </div>
                  <div class="text-center">
                    <soft-button
                      class="mt-0 mb-3"
                      variant="gradient"
                      color="warning"
                      full-width
                      :disabled="disabled || code.join('').length<6"
                      @click="verify"
                      >Verify
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="m-3 position-relative bg-gradient-warning h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="../../assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="../../assets/img/illustrations/danger-chat-ill.png"
                    alt="chart-ill"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  "Attention is the new currency"
                </h4>
                <p class="text-white">
                  Stay one step ahead of hackers with two-step verification.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftButton from "@/components/Soft/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "Verification",
  components: {
    SoftButton,
  },
  data() {
    return {
      code: ['', '', '', '', '', ''],
      token: "",
      enabled: false,
      otp_secret: "",
      disabled: false,
    };
  },
  created() {
    localStorage.getItem("2fa_token") ? this.token = localStorage.getItem("2fa_token") : this.$router.push({ name: "Signin Illustration" });
    localStorage.removeItem("2fa_token");

    const payload = JSON.parse(Buffer.from(this.token.split(".")[1],'base64').toString('ascii')).obj;

    this.enabled = payload['2fa'];
    if (!this.enabled) {
      this.otp_secret = 'https://api.365.day/v1/qrcode?text=otpauth://totp/365Day:'+ payload['email'] + '?secret=' + payload['otp_secret'] + '&issuer=365Day';
    }

    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    nextFocus(el,index) {
      let dom = document.getElementsByTagName("input"),
        currInput = dom[index],
        nextInput = dom[index + 1],
        lastInput = dom[index - 1];

      if (el.keyCode != 8) {
        if (index < (this.code.length - 1)) {
          nextInput.focus();
        } else {
          currInput.blur();
        }
      }else{
        if (index !=0) {
          lastInput.focus();
        }
      }

    },
    showSwal(type, title, text) {
      if (type === "warning") {
        this.$swal({
          title: title,
          text: text,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      } else if (type === "success") {
        this.$swal({
          title: title,
          text: text,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      }
    },
    verify() {
      axios.post('https://api.365.day/v1/auth/2fa', {
        otp: this.code.join(''),
      },{
        headers: {
          'Authorization': 'Bearer ' + this.token,
        }
      }).then((response) => {
        localStorage.setItem("refresh_token", response.data.refresh_token);
        this.$router.push({ name: "/" });
      }).catch((error) => {
        this.showSwal('warning', 'Warning', error.response.data);
        this.disabled = false;
      });
    },
  },
};
</script>
