<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your username and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="submit">
                    <div class="mb-3">
                      <soft-input
                        id="email"
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        :value="email"
                        @input="email = $event.target.value"
                      />
                    </div>
                    <div class="mb-3" v-show="validateEmail()">
                      <soft-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        :value="password"
                        @input="password = $event.target.value"
                      />
                    </div>

                    <div class="text-center">
                      <div id="recaptcha-container" v-show="password.length>7"></div>
                      <soft-button
                        id="submit"
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        full-width
                        size="lg"
                        :disabled="disabled || password.length<8 || !validateEmail()"
                      >Sign in
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Signup' }"
                      class="text-success text-gradient font-weight-bold"
                    >
                      Sign up
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="m-3 position-relative bg-gradient-success h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                  src="@/assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="@/assets/img/illustrations/chat.png"
                    alt="chat-img"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  Attention is the new currency
                </h4>
                <p class="text-white">
                  Access your account and take your subtitles to the next level with our user-friendly login.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftInput from "@/components/Soft/SoftInput.vue";
import SoftButton from "@/components/Soft/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
import { onMounted } from "vue";
import { nanoid } from "nanoid";
import axios from "axios";
export default {
  name: "Signin",
  components: {
    SoftInput,
    SoftButton,
  },
  setup() {
    onMounted(() => {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    });
  },
  data() {
    return {
      email: "",
      password: "",
      disabled: false,
      cdata: "",
      token: ""
    };
  },
  created() {
    this.cdata = nanoid(10);
    window.onloadTurnstileCallback = () => {
      window.turnstile.render("#recaptcha-container", {
        sitekey: "0x4AAAAAAABffqkUtZpTv4bi",
        action: 'login',
        cData: this.cdata,
        callback: (response) => {
          this.token = response;
        },
      });
    };
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    submit() {
      this.disabled = true;
      axios
        .post("https://api.365.day/v1/auth/token", {
          email: this.email,
          password: this.password,
          recaptcha_response: this.token,
          cdata: this.cdata
        })
        .then((r) => {
          localStorage.setItem("2fa_token", r.data["2fa_token"]);
          this.$router.push({ name: "Verification" });
        })
        .catch((error) => {
          this.showSwal('warning', 'Warning', error.response.data);
          this.disabled = false;
        });
    },
    showSwal(type, title, text) {
      if (type === "warning") {
        this.$swal({
          title: title,
          text: text,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      } else if (type === "success") {
        this.$swal({
          title: title,
          text: text,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          preConfirm: () => {
            this.$router.push({ name: "Verification Illustration" });
          },
          buttonsStyling: false,
        });
      }
    },
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
