<template>
  <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">{{this.$parent.title}}</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search" class="table table-flush">
            <thead class="thead-light">
            <tr>
              <th>Task Name</th>
              <th>File Name</th>
              <th>Purpose</th>
              <th>Created At</th>
              <th>More</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="file in this.$parent.files" :key="file.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ file.project_name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-sm text-secondary mb-0">{{ file.original_name }}</p>
              </td>
<!--              <td>-->
<!--              <span class="badge badge-dot me-4">-->
<!--                <i class="bg-info"></i>-->
<!--                <span class="text-dark text-xs">{{ file.status }}</span>-->
<!--              </span>-->
<!--              </td>-->
<!--              <td class="align-middle text-center text-sm">-->
<!--                <p class="text-secondary mb-0 text-sm">{{ file.project }}</p>-->
<!--              </td>-->
              <td class="align-middle text-center">
                <span class="text-secondary text-sm">{{ file.purpose }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-sm">{{ new Date(file.created_at).toLocaleDateString('en-GB') }}</span>
              </td>
              <td class="align-middle text-center">
<!--                <a href="/" class="text-secondary font-weight-bold text-xs"-->
<!--                >More</a-->
<!--                >-->
                <a
                  href="https://tools.365.day/applications/files/b916003f62354c1781ad6ca04a3cc077">
                  <soft-button
                  >
                    More
                  </soft-button>
                </a>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import SoftButton from "@/components/Soft/SoftButton.vue";

export default {
  name: "Table",
  components: { SoftButton },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: true,
    });
  },
};
</script>
