<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
          <div class="mt-4 row">
            <div class="col-12">
              <div class="card">
                <!-- Card header -->
                <div class="card-header">
                  <h5 class="mb-0">Me at the zoo.mp4</h5>
                </div>
                <div class="table-responsive">
                  <div style="position: relative; padding-top: 40%;"><iframe src="https://customer-n0jfbzmde3of7oq1.cloudflarestream.com/c6d11c21fb864e5ea1f0d618c66c6b36/iframe?poster=https%3A%2F%2Fcustomer-n0jfbzmde3of7oq1.cloudflarestream.com%2Fc6d11c21fb864e5ea1f0d618c66c6b36%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "Media",
  // mounted() {
  //   const path = window.location.pathname;
  //   const pathArray = path.split("/");
  //   const thirdLevelDirectory = pathArray[3];
  //   this.title = thirdLevelDirectory;
  // },

};
</script>
