<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <Table />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/Table.vue";

export default {
  name: "Media",
  components: {
    Table,
  },
  data() {
    return {
      title: "Youtube test video",
      files : [
        {
          "original_name": "Me at the zoo.mp4",
          "id": "80431b53ac1b4183835957941485cb8a"
        },
        {
          "original_name": "Me at the zoo.mp3",
          "id": "80431b53ac1b4183835957941485cb8b"
        },
        {
          "original_name": "Me at the zoo_en.srt",
          "id": "80431b53ac1b4183835957941485cb8c"
        },
        {
          "original_name": "Me at the zoo_de.srt",
          "id": "80431b53ac1b4183835957941485cb8d"
        },
        {
          "original_name": "Me at the zoo_fr.srt",
          "id": "80431b53ac1b4183835957941485cb8e"
        },
        {
          "original_name": "Me at the zoo_en.vtt",
          "id": "80431b53ac1b4183835957941485cb8g"
        },
        {
          "original_name": "Me at the zoo_de.vtt",
          "id": "80431b53ac1b4183835957941485cb8f"
        },        {
          "original_name": "Me at the zoo_fr.vtt",
          "id": "80431b53ac1b4183835957941485cb8m"
        }
      ]
    };
  },
  // mounted() {
  //   const path = window.location.pathname;
  //   const pathArray = path.split("/");
  //   const thirdLevelDirectory = pathArray[3];
  //   this.title = thirdLevelDirectory;
  // },

};
</script>
